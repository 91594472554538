<template>
  <div class="container-fluid">
    <h3>Influencers</h3>
    <b-card no-body>
      <b-tabs content-class="mt-2" fill card
              v-model="tabIndex">
        <b-tab :title-link-class="linkClass(0)"
               title="Influencer List" active>
          <influencer-list />
        </b-tab>
        <b-tab :title-link-class="linkClass(1)"
               title="Schedule" lazy>
          <schedule />
        </b-tab>
      </b-tabs>
    </b-card>
    
    <!-- Dialog -->
    <v-dialog/>

    <!-- Modal -->
    <modals-container />
  </div>
</template>

<script>
  const InfluencerList = () => import('./InfluencerList.vue');
  const Schedule = () => import('./Schedule.vue');

  export default {
    name : 'Influencers',
    data() {
      return {
        tabIndex : 0,
      }
    },
    methods : {
      linkClass(idx) {
        if (this.tabIndex === idx)
          return [ 'bg-primary', 'text-light', 'font-weight-bold', 'inf-tab' ];
        
        return [ 'bg-light', 'text-info', 'inf-tab' ];
      },
    },
    components : {
      InfluencerList,
      Schedule,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/influencers";
</style>